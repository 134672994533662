.side-menu-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: flex-start;
    height: 100%;
    width: 100%;
    padding: 30px;
    padding-top: 30px;
}

.side-menu-header{
  cursor: pointer;
}

.side-menu-content{
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: flex-start;
}
.menu-links > a{
  padding: 10px;
}

.user-tile{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  width: 100%;
  height: 100%;
}
.user-tile > img{
  height: 20pt;
  margin-right: 10pt;
}

.cart-well{
  background-color: #eee;
  border-radius: 10px;
  box-shadow: 0px 0px 10px #ddd inset;
}

.cart-item{
  /* height: 80px; */
  padding-bottom: 20px;
}
.cart-item:last-of-type{
  padding-bottom: 0px;
}