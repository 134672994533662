.no-package-popup {
    border-radius: 10px;
    padding: 20px;
    margin: 0 10px;
}

.prohibited-item-card-wrapper>.card {
    border: 0;
    border-radius: 0;
    border-bottom: 1px dashed #c4c4c4;
}

.declaration-list>li {
    color: #626265;
    font-size: 14px;
}

.modal-footer.declaration-footer {
    display: flex;
    justify-content: center;
}

.import-search-bar {
    padding: 0px 20px;
    border-radius: 8px;
    border: 1px solid #d4d4d4;
}

.export-search-bar {
    padding: 0px 12px;
    border-radius: 8px;
    border: 1px solid #d4d4d4;
}

.how-it-works-modal-title {
    font-weight: 700;
    font-size: 18px;
    line-height: 19px;
    color: #6E8FE2;
    text-transform: uppercase;
}

.help-item {
    zoom: 0.8;
    font-weight: 600;
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
}

.help-item>img {
    /* width: 70%; */
    height: 150px;
    padding-bottom: 10px;
}

.help-item>.ball {
    width: 20px;
    height: 20px;
    padding-top: 15px;
    background-color: #79E3B6;
    border-radius: 50%;
}

.ball-line {
    border-top: 2px solid #79E3B6;
    width: 595px;
    margin: auto;
    margin-top: -8px;
}

@media only screen and (max-width: 991px) {
    .help-item {
        justify-content: center;
        /* height: 180px; */
    }
}