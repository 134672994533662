/* HEADER STYLE */

.site-banner {
    height: 50px;
    background-color: #d73b7d;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: 600;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
}

.header {
    box-shadow: 0px 8px 20px rgba(27, 27, 27, 0.15);
    background-color: #FBFAFE;
}

.sticky-top {
    background-color: #FBFAFE;
    z-index: 200;
}


/* .header {
    height: 70px;
}

.header-fixed {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1000;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px -5px 16px 0px rgb(0 0 0 / 20%);
}

.header-mobile {
    height: 60px;
    z-index: 1001;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px -5px 16px 0px rgba(0, 0, 0, 0.767);
}

.header-fixed img {
    height: 30px;
}
 */


/* .navbar-brand {
    height: 30px;
    margin-bottom: 5px;
}

.navbar-brand img {
    height: 100%;
} */

.bottom-nav {
    height: 55px;
    display: flex;
    background-color: #fff;
    flex-direction: row;
    justify-content: center;
    box-shadow: 0px 6px 14px 0px rgb(0 0 0 / 77%);
    z-index: 100;
}

.bottom-nav-btn-wrapper {
    width: 350px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.bottom-nav-btn {
    color: #3c3c3c;
    width: 70px;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    justify-content: space-between;
    align-items: center;
    background-color: transparent !important;
    border: none !important;
    cursor: pointer;
    /* font-weight: 500; */
}

.action-btn,
.action-btn:hover,
.action-btn:focus,
.action-btn:active {
    border-radius: 8px;
    background: #744ED5;
    border: none;
}

.offcanvas {
    overflow-y: scroll;
}

.offcanvas-bottom {
    min-height: 400px;
}


/* .bottom-nav-btn.active {
    color: #d73b7d;
    font-weight: 900;
} */

.otp-input {
    /* width: 1.25em !important; */
    width: 40px !important;
    margin-left: 0.25em;
    margin-right: 0.25em;
    padding: 5px 0px !important;
    border-radius: 8px;
    background: #F3F7FF;
    border: 1px solid #E3EAFC;
    font-weight: 600;
    /* border-width: 0 !important;
    border-bottom-width: 3px !important;
    border-radius: 0px;
    border-color: #d73b7d96 !important; */
}


/* BODY STYLE */


/* .page-body {
    overflow: hidden;
} */

.section {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.reverse {
    flex-direction: row-reverse;
}

.landing {
    margin-bottom: 60px;
}


/* BANNER */

.fab {
    cursor: pointer;
    background: #d73b7d;
    outline: 5px solid #d73b7c50;
    color: cornsilk;
    height: 50px;
    width: 50px;
    font-weight: 600;
    font-size: 20pt;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 40px;
    position: fixed;
    bottom: 15px;
    Right: 35px;
    z-index: 1000;
    box-shadow: 3px 3px 4px 5px rgb(0 0 0 / 20%);
}

.banner {
    align-items: center;
    padding-bottom: 30px;
}

.banner-card {
    padding: 30px;
    /* padding: 24px 16px; */
    border-radius: 0px;
    background-color: rgb(246 249 254);
    /* background-color: #F8FAFF; */
}

.rounded-top {
    border-top-left-radius: 50px !important;
    border-top-right-radius: 50px !important;
}

.order-card>.card-body {
    justify-content: flex-start;
    /* padding-top: 60px; */
}

.order-container {
    border-radius: 10px;
    background-color: #e9e9e9;
    box-shadow: 0px 0px 10px 5px rgba(87, 87, 87, 0.123) inset;
    -webkit-box-shadow: 0px 0px 10px 5px rgba(87, 87, 87, 0.123) inset;
    -moz-box-shadow: 0px 0px 10px 5px rgba(87, 87, 87, 0.123) inset;
    padding-top: 20px;
    flex: 1;
}

.banner-title {
    font-family: 'Merriweather', serif;
    font-size: 30pt;
    line-height: 48pt;
    font-weight: 600;
    text-transform: capitalize;
}

.banner-subtitle {
    padding: 35px 5px;
    font-weight: 500;
    font-size: 18pt;
}

.banner-cta {
    justify-self: start;
    display: flex;
    justify-content: space-around;
}

.banner-cta input {
    flex: 1;
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
}

.banner-cta button {
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
}

.banner-image {
    z-index: -100;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.banner-image img {
    padding: 10px;
    padding-top: 50px;
    height: 100%;
    opacity: 0.9;
}

.carousel-label {
    font-weight: 900;
    text-align: center;
    margin-bottom: -30px;
    font-family: sans-serif;
    font-size: 30pt;
    color: #d73b7d;
    opacity: 0.8;
}

.card-usp {
    box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 20%);
    transition: all 0.1s linear;
}

.card-usp:hover {
    box-shadow: 0px 3px 5px 0px rgb(0 0 0 / 20%);
}

.section-card {
    border-radius: 0px;
    padding-top: 20px;
}

.section-card .card-itm {
    margin: 20px 0px;
}

.center-title {
    padding: 55px 0 30px 0;
}


/* .cards-row {
    padding-bottom: 20px;
    padding: 10px 90px;
} */

.cards-row .card {
    border-radius: 8px;
    transition: all 0.3s ease-in;
    margin-top: 0px;
    margin-bottom: 0px;
    height: 180px;
}


/* .cards-row .card:hover, .cards-row .card.active{
  margin-top: -5px;
  margin-bottom: 5px;

} */

.card-img {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.card-img img {
    max-height: 130px;
    max-width: 100%;
    aspect-ratio: 1;
    padding-bottom: 10px;
}

.card-text {
    flex: 1 1;
    padding: 0 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #05336f;
    font-weight: 600;
    font-size: 11pt;
    line-height: 13pt;
    text-align: center;
}

.landing-search-card {
    /* box-shadow: 0px 2px 10px 0px rgb(215 59 125 / 60%); */
    margin-top: 30px;
    margin-bottom: 18px;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.landing-search {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
}

.landing-search-input {
    width: 100%;
}

.landing-usp {
    width: 100%;
    height: 100px;
    /* background-color: red; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
}

.landing-logo-slider {
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.landing-logo-slider img {
    max-height: 60px;
    max-width: 60%;
}

.shipper-logo-container {
    flex-wrap: wrap;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding-top: 20px;
}

.shipper-logo-container div {
    padding: 25px;
    width: 250px;
    display: flex;
    justify-content: center;
}

.shipper-logo-container div img {
    /* width: 100%; */
    max-height: 80px;
    max-width: 100%;
}

.login-modal-body {
    z-index: 999;
    top: 50px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.form-input-label {
    font-weight: 600;
    font-size: 11px;
    color: #626265;
    margin-top: 10px;
    margin-bottom: 5px;
}

.modal-title {
    padding: 10px;
    font-size: 20pt;
    font-weight: 600;
}

.form-modal-title {
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: 700;
    color: #744ED5;
}

.modal-form-field {
    margin-bottom: 10px;
    /* display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center; */
}

.modal-form-input {
    width: 100%;
}

.modal-form-field text {
    width: 220px;
    font-weight: 600;
}

.modal-form-field text.required::after {
    content: "*";
    color: rgb(184, 33, 33);
    font-weight: 600;
}

.modal-form-field input {
    width: 100%;
}

.modal-form-field textarea {
    width: 100%;
    height: 80px;
    overflow-y: scroll
}

.error-text {
    color: #D4586A;
    font-weight: 500;
    font-size: 12px;
    /* margin-bottom: 15px; */
    margin-top: 5px;
}

.error-border-parent input,
.error-border {
    border: 1px solid #D4586A !important;
}

.login-field {
    margin: 10px !important;
    justify-content: center !important;
}

.login-label {
    width: 90px !important;
}

.login-input {
    width: 220px !important;
}

.login-error {
    margin-bottom: -5px;
    margin-left: 5px;
}

.landing-address {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.landing-address>div {
    padding: 10px 10px 10px 20px;
    width: 400px;
    max-width: 650px;
    margin-bottom: 10px;
}

.landing-input {
    border: none;
    border-radius: 10px;
}

.landing-input:focus-visible {
    outline-color: none !important;
    outline-style: none;
    outline-width: 2px;
}

.landing-dropdown-select {
    color: #3c3c3c;
    border: #b3b3b3 1px solid;
    width: 330px;
    height: 70px;
    color: #3c3c3c;
    border: #b3b3b3 1px solid;
    transition: all 0.4s ease-out;
}

.landing-dropdown-menu {
    width: 100%;
}

.address-well {
    min-height: 20px;
    width: calc(100% - 30px);
    padding: 10px;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 20px;
    background-color: #f5f5f5;
    border: 1px solid #e3e3e3;
    font-size: 10pt;
    text-transform: capitalize;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 5%);
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 5%);
}

.address-bridge {
    position: relative;
    justify-self: center;
    align-self: center;
    width: 20%;
    top: 46px;
    z-index: 1;
    height: 2px;
    border: #d73b7d 2px dashed;
}

#tooltip-landing>.tooltip-inner {
    background-color: #d73b7cb0;
    color: #fff;
    font-weight: 800;
    border: 1px solid #d73b7cb0;
}


/* #tooltip-landing>.tooltip-arrow {
    border-top: 5px solid #d73b7cb0;
} */

.landing-error {
    border: rgb(241, 169, 169) 2px solid !important;
    background-color: rgb(255, 232, 232) !important;
}

.landing-error {
    outline-color: rgb(241, 169, 169) !important;
}

.landing-error,
.landing-error>input::placeholder {
    color: rgb(184, 33, 33) !important;
}

.landing-error input:focus-visible {
    outline-color: rgb(255, 232, 232) !important;
    outline-width: 0;
}

input::-webkit-outer-spin-button,
.booking-search-card {
    /* box-shadow: 0px 2px 10px 0px rgb(215 59 125 / 60%); */
    margin-top: 30px;
    margin-bottom: 30px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.international-booking-edit {
    position: absolute;
    top: 0px;
    right: 50px;
    width: 77px;
    zoom: 0.8;
}

.chat-bot {
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 9999;
}

.pulse-div {
    z-index: 1000 !important;
}

.question-text {
    font-weight: 600;
}

.coupon-input-btn {
    position: absolute;
    color: #8f8f8f;
    top: 8px;
    right: 8px;
    cursor: pointer;
    margin-left: 8px;
}

.handoff-wrapper {
    color: white;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding: 35px;
    background-color: #fff;
    height: 100%;
    width: 100%;
    z-index: 2147483601;
    align-items: center;
    -webkit-transition: background-color 400ms linear;
    -ms-transition: background-color 400ms linear;
    transition: background-color 400ms linear;
}

.handoff-slide0 {
    background-color: #744ED5;
}

.handoff-slide1 {
    background-color: #5D5ED6;
}

.handoff-slide2 {
    background-color: #456ED6;
}

.handoff-pill {
    background: white;
    height: 10px;
    width: 10px;
    border-radius: 30px;
    margin-right: 7px;
    opacity: 0.5;
    -webkit-transition: width 400ms linear, opacity 400ms linear;
    -ms-transition: width 400ms linear, opacity 400ms linear;
    transition: width 400ms linear, opacity 400ms linear;
}

.handoff-pill.active {
    opacity: 1;
    width: 24px;
}


/* FOOTER */

.footer {
    background-color: #fff;
    padding: 50px 0;
}

.footer-logo img {
    height: 20px;
}

.footer-text {
    color: #696969;
    font-size: 10pt;
    line-height: 13pt;
    text-align: justify;
    text-justify: inter-word;
}

.footer-about-text {
    padding: 20px 0;
}

.footer-copyright-text {
    font-size: 8pt;
    color: #4d4d4d;
}

.copyright-symbol {
    font-size: 12pt;
}

.footer-links {
    display: flex;
    flex-direction: column;
    font-weight: 600;
}

.footer-social-media {
    font-size: 20pt
}

.footer-social-media svg {
    cursor: pointer;
    margin-right: 15px;
    color: #4d4d4d;
    transition: all 0.3s ease-in;
}

.footer-social-media svg:hover {
    margin-right: 15px;
    color: #d73b7d;
}

/* Otpless */
#otpless-floating-button {
    display: none !important;
}

/* Extra small devices (phones, 600px and down) */

@media only screen and (max-width: 991px) {
    .container-sm {
        padding: 0px;
    }

    .landing {
        margin-bottom: 60px;
        flex-direction: column-reverse;
    }

    .banner {
        padding-left: 30px;
        padding-right: 30px;
        padding-bottom: auto;
        min-height: 420px;
        justify-content: center;
    }

    .banner-card {
        padding: 20px 0px;
        border-radius: 0px;
    }

    .banner-title {
        font-size: 18pt;
        line-height: 23pt;
        text-align: center;
    }

    .banner-subtitle {
        padding: 0px 5px 10px 5px;
        font-size: 9pt;
        text-align: center;
    }

    .banner-image img {
        opacity: 0.8;
        width: 100%;
    }

    .carousel {
        width: 100%;
    }

    .carousel-label {
        margin-bottom: -50px;
        padding-top: 20px;
    }

    .banner-cta {
        height: 40px;
    }

    .banner-cta button {
        font-size: 7pt;
    }

    .cards-row {
        padding-top: 20px;
        padding-left: 0px;
        padding-right: 0px;
    }

    .cards-row .card {
        margin: auto;
        margin-top: 20px;
        aspect-ratio: 1;
    }

    .usp-tile {
        margin-bottom: 40px;
    }

    .shipper-logo-container img {
        max-height: 50px;
    }

    .order-container {
        padding-top: 0px;
    }
}

.landing-title-text {
    margin-bottom: 10px;
    font-size: 20pt;
    font-weight: 800;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.landing-category-btn-wrapper {
    /* width: 600px; */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.landing-category-btn {
    height: 120px;
    width: 130px;
    display: flex;
    flex-direction: column;
    margin: 10px;
    margin-bottom: 30px;
    /* flex-basis: 25%; */
}

.landing-category-btn>.btn-body {
    width: 100%;
    zoom: 0.8;
    flex: 1;
    background-color: #fff;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    -webkit-box-shadow: 0 6px 10px rgb(52 105 203 / 40%);
    box-shadow: 0 6px 10px rgb(52 105 203 / 40%);
    z-index: 6;
}

.landing-category-btn>.btn-body>.btn-img {
    max-height: 50px;
    max-width: 50px;
    margin: auto;
}

.landing-category-btn>.btn-body>.btn-text {
    font-weight: 800;
    padding-bottom: 10px;
}

.landing-category-btn>.btn-footer {
    text-align: center;
    zoom: 0.8;
    margin-top: -8px;
    padding: 5px;
    padding-top: 15px;
    background: #d4e5ff;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    z-index: 5;
    color: #aa1c57;
    font-weight: 800;
    font-size: 9pt;
}


/* Small devices (portrait tablets and large phones, 600px and up) */

@media only screen and (min-width: 600px) {}


/* Medium devices (landscape tablets, 768px and up) */

@media only screen and (min-width: 768px) {}

.container-sm,
.container {
    max-width: 1150px;
}

@media only screen and (max-width: 280px) {

    section,
    .header,
    .footer,
    .modal-dialog {
        zoom: 0.6;
    }
}


/* SPECIAL */

[data-provider-id="phone"] {
    color: #fff;
    background-color: #d73b7d;
    border-color: #d73b7d;
}

[data-provider-id="phone"]:hover {
    color: #fff;
    background-color: #aa1c57;
    border-color: #aa1c57;
}

.btn-check:active+[data-provider-id="phone"],
.btn-check:checked+[data-provider-id="phone"],
[data-provider-id="phone"].active,
[data-provider-id="phone"]:active,
.show>[data-provider-id="phone"].dropdown-toggle {
    color: #fff;
    background-color: #aa1c57;
    border-color: #aa1c57;
}

.big-text {
    margin-top: 20px;
    margin-bottom: -40px;
    font-size: 45pt;
    text-align: center;
}

.order-detail-subheading {
    font-weight: 600;
    text-transform: capitalize;
    font-size: 12px;
}

@media only screen and (max-width: 991px) {
    .big-text {
        margin-top: 20px;
        margin-bottom: -20px;
        font-size: 35pt;
        text-align: center;
    }

    .pc-only {
        display: none;
    }

    .landing-dropdown-select {
        width: 283px;
        height: 60px;
    }

    .landing-search-card {
        padding: 5px;
        margin-top: 5px;
    }

    .landing-category-btn-wrapper {
        width: auto;
    }

    .mobile-text {
        zoom: 0.5;
    }

    .landing-address>div {
        width: 100%;
    }

    .order-card>.card-body {
        padding-top: 0px;
    }

    .modal-form-field {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .modal-form-field .modal-form-input {
        margin: 0px;
    }
}


/* .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: #d73b7d!important;
} */

.review-bullet.swiper-pagination-bullet {
    opacity: 1;
    border-radius: 4px !important;
    width: 8px;
    height: 8px;
    background-color: #E9E3F9 !important;
    transition: all 300ms linear;
}

.review-bullet.swiper-pagination-bullet.swiper-pagination-bullet-active {
    width: 18px;
    background-color: #9A7CE5 !important;
}

@media only screen and (max-width: 991px) {
    .home-pc-only {
        display: none;
    }

    .fc-widget-normal {
        display: none;
    }

    .home-fullwidth-on-mobile {
        width: 100% !important;
        max-width: 100% !important;
    }

    .mobile-only-padding {
        padding: 0px 25px !important;
    }
}

@media only screen and (min-width: 990px) {
    .mobile-only {
        display: none;
    }
}

.gradient-title{
    /* min-height: 230px; */
    margin-bottom: 40px;
    font-family: Inter;
    font-size: 45px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    background: var(--linear-gradient, linear-gradient(95deg, #263981 8.7%, rgba(152, 67, 218, 0.92) 42.71%, rgba(67, 182, 218, 0.92) 72.1%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.subtitle-below-gradient{
    color: var(--Black_Primary, #1B1B1B);
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    margin: 20px 0px;
    font-weight: 600;
    line-height: 32px; /* 200% */
}

.slider-cta-btn{
    color: var(--Black_Primary_S01, #101010);
    font-family: Open Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 40px;
    margin-left: 5px;
    padding: 16px 32px;
    border-radius: 30px;
    border: 1px solid var(--Black_Primary_S01, #101010);
    box-shadow: 14px 17px 40px 4px rgba(112, 144, 176, 0.08);
    background-color: white;
    transition: all 0.2s ease-in-out;
}

.slider-cta-btn.compact{
    font-size: 12px;
    margin: 0px;
    height: 32px;
    padding: 10px 18px;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.slider-cta-btn:hover, .slider-cta-btn:active{
    background-color: #101010;
    color: white;
}

.widget-text{
    color: var(--saas-white, #FFFFFE);
    font-family: Open Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.widget-title{
    font-size: 18px;
    font-weight: 600;
}

.mantine-Tabs-tabsListWrapper{
    border-bottom: 3px solid #ffffff4d;
}

.widget-tabs .mantine-Tabs-tabControl.mantine-Tabs-tabActive{
    border-bottom: 3px solid #fff;
}

.widget-tabs .mantine-Tabs-tabControl .mantine-Tabs-tabLabel{
    color: white;
    font-weight: 600;
    opacity: 0.6;
    margin-left: 10px;
    margin-right: 10px;
}
.widget-tabs .mantine-Tabs-tabControl.mantine-Tabs-tabActive .mantine-Tabs-tabLabel{
    color: white;
    font-weight: 900;
    opacity: 1;
}

.lw-action-card{
    padding-bottom: 8px;
    transition: all 300ms ease-in-out;
    box-shadow: 0px 8px 20px 0px rgba(90, 70, 175, 0.15);
}

.lw-action-card:hover{
    /* padding-bottom: 18px; */
    box-shadow: 0px 8px 20px 7px rgba(89, 70, 175, 0.387);
}

.lw-action-card-inner{
    margin-top: 0px;
    margin-bottom: 0px;
    transition: all 300ms ease-in-out;
}

.lw-action-card-inner:hover{
    margin-top: -10px;
    margin-bottom: 10px;
}
@media screen and (-webkit-min-device-pixel-ratio:0) { 
    select,
    textarea,
    input {
      font-size: 16px !important;
    }
  }