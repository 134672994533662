/* @font-face {
    font-family: 'Fira Sans';
    src: local('Fira Sans'), url(./assets/fonts/FiraSans-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Fira Sans';
    font-weight: 600;
    src: local('Fira Sans'), url(./assets/fonts/FiraSans-Medium.ttf) format('truetype');
}

@font-face {
    font-family: 'Fira Sans';
    font-weight: 800;
    src: local('Fira Sans'), url(./assets/fonts/FiraSans-SemiBold.ttf) format('truetype');
}

@font-face {
    font-family: 'Fira Sans';
    font-weight: 900;
    src: local('Fira Sans'), url(./assets/fonts/FiraSans-Bold.ttf) format('truetype');
} */

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cookie&display=swap');
@import url("https://fonts.googleapis.com/css2?family=SF+Pro+Text:wght@400;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;600&display=swap");
/* @import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500;600&display=swap");


body {
    margin: 0;
    line-height: normal;
}

:root {
    /* fonts */
    --body-regular: "SF Pro Text";
    --font-roboto: Roboto;
    --font-inter: Inter;
    --font-poppins: Poppins;

    /* font sizes */
    --body-regular-size: 17px;
    --font-size-2xs: 11px;
    --callout-bold-size: 16px;
    --font-size-xs: 12px;
    --font-size-sm: 14px;
    --font-size-lg: 18px;

    /* Colors */
    --color-royalblue-100: #007aff;
    --label-color-light-primary: #000;
    --color-whitesmoke-100: #f2f2f2;
    --color-dimgray-200: #635f5f;
    --color-dimgray-100: #565656;
    --white: #fff;
    --separator-color-light-with-transparency: rgba(60, 60, 67, 0.36);
    --color-mediumslateblue-100: #9473ff;
    --color-mediumslateblue-200: #7a5af8;
    --color-mediumslateblue-300: #2962ff;
    --color-mediumslateblue-400: rgba(41, 98, 255, 0.08);
    --color-darkgray-100: #9a97a5;
    --color-darkgray-200: rgba(154, 151, 165, 0.4);
    --color-darkgray-300: rgba(154, 151, 165, 0.28);
    --color-gainsboro: #e3e2e6;

    /* Gaps */
    --gap-3xs: 10px;
    --gap-17xl: 36px;
    --gap-8xs: 5px;
    --gap-xl: 20px;
    --gap-12xl: 31px;
    --gap-21xl: 40px;
    --gap-5xs: 8px;
    --gap-10xl: 29px;
    --gap-10xs: 3px;
    --gap-5xl: 24px;
    --gap-28xl: 47px;
    --gap-7xs: 6px;
    --gap-6xs: 7px;
    --gap-base: 16px;
    --gap-2xl: 21px;

    /* Paddings */
    --padding-4xs: 9px;
    --padding-base: 16px;
    --padding-3xs: 10px;
    --padding-6xs: 7px;
    --padding-xl: 20px;
    --padding-84xl: 103px;
    --padding-48xl: 67px;
    --padding-25xl: 44px;
    --padding-5xs: 8px;
    --padding-xs: 12px;
    --padding-smi: 13px;
    --padding-2xl: 21px;
    --padding-2xs: 11px;
    --padding-mini: 15px;
    --padding-9xs: 4px;
    --padding-11xs: 2px;
    --padding-15xl: 34px;
    --padding-7xs: 6px;
    --padding-30xl: 49px;
    --padding-26xl: 45px;
    --padding-31xl: 50px;
    --padding-28xl: 47px;
    --padding-mid: 17px;
    --padding-sm: 14px;
    --padding-99xl: 118px;

    /* Border radiuses */
    --br-11xs: 2px;
    --br-3xs: 10px;
    --br-5xl: 24px;
    --br-5xs: 8px;
    --br-7xs: 6px;
    --br-9xs: 4px;
}


.frame-icon {
    height: 100px;
    flex: 1;
    position: relative;
    max-width: 100%;
    overflow: hidden;
}

.frame-wrapper {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 0 var(--padding-7xs);
    box-sizing: border-box;
    max-width: 100%;
}

.input-details-child {
    height: 28.5px;
    width: 28px;
    position: relative;
    border-radius: 50%;
    background-color: rgba(122, 90, 248, 0.2);
    box-shadow: 0 4px 2px rgba(0, 0, 0, 0.02);
}

.order-request-form {
    position: relative;
    font-weight: 600;
    text-shadow: 0 4px 2px rgba(0, 0, 0, 0.02);
}

.input-details {
    height: 30px;
    margin: 0 !important;
    position: absolute;
    top: calc(50% - 14px);
    left: -7px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    gap: var(--gap-3xs);
}

.frame-icon1 {
    height: 14px;
    flex: 1;
    position: relative;
    max-width: 100%;
    overflow: hidden;
    /* z-index: 1; */
}

.frame-group-a,
.input-details-parent {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;
}

.input-details-parent {
    flex: 1;
    position: relative;
}

.frame-group-a {
    /* width: 704px; */
    padding: 0 var(--padding-xl) var(--padding-7xs);
    box-sizing: border-box;
}

.please-share-below {
    width: 219px;
    line-height: 16px;
    display: inline-block;
}

.from-address,
.name,
.please-share-below {
    position: relative;
    font-weight: 600;
}

.name {
    font-weight: 500;
}

.input-fields-frame {
    border: 1px solid var(--color-darkgray-200);
    outline: 0;
    background-color: var(--white);
    align-self: stretch;
    height: 38px;
    position: relative;
    border-radius: var(--br-9xs);
    box-sizing: border-box;
    min-width: 142px;
    padding: var(--padding-3xs) var(--padding-26xl) var(--padding-3xs) var(--padding-smi);
}

.to-address-frame {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-5xs);
}

.contact,
.counter-code {
    position: relative;
    font-weight: 500;
}

.countercode {
    width: 302px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    gap: var(--gap-xl);
}

.address-input-frame,
.shipment-details-box {
    border: 1px solid var(--color-darkgray-300);
    outline: 0;
    background-color: var(--white);
    height: 36px;
    flex: 1;
    position: relative;
    border-radius: var(--br-9xs);
    box-sizing: border-box;
    min-width: 153px;
}

.frame,
.nested-frames,
.text-frame-a {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.frame {
    align-self: stretch;
    /* overflow: hidden; */
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap-base);
}

.nested-frames,
.text-frame-a {
    flex-direction: column;
}

.nested-frames {
    align-self: stretch;
    gap: var(--gap-5xs);
}

.text-frame-a {
    width: 100%;
    gap: var(--gap-base);
    max-width: 100%;
    font-size: var(--font-size-sm);
}

.to-address-label {
    align-self: stretch;
    border-radius: var(--br-5xs);
    border: 1px solid var(--color-gainsboro);
    box-sizing: border-box;
    align-items: flex-start;
    padding: var(--padding-base) var(--padding-base) var(--padding-mid);
    gap: var(--gap-2xl);
    /* z-index: 1; */
    font-family: var(--font-roboto);
}

.address-section,
.please-share-below-details-parent,
.to-address-label {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    max-width: 100%;
}

.please-share-below-details-parent {
    align-self: stretch;
    align-items: flex-start;
    gap: 18px;
}

.address-section {
    width: 678px;
    border-radius: var(--br-5xs);
    background-color: var(--white);
    border: 1px solid var(--color-darkgray-300);
    box-sizing: border-box;
    flex-shrink: 0;
    align-items: center;
    padding: 52px 23px 39px;
    gap: var(--gap-5xl);
    font-size: var(--callout-bold-size);
    font-family: var(--font-inter);
}

.length-input-icon {
    height: 28px;
    width: 28px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
}

.shipment-details1 {
    position: relative;
    font-weight: 600;
    text-shadow: 0 4px 2px rgba(0, 0, 0, 0.02);
}

.shipment-details,
.shipment-dimensions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-3xs);
}

.shipment-details {
    width: 718px;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 var(--padding-xl);
    box-sizing: border-box;
    gap: var(--gap-xl);
    max-width: 100%;
    flex-shrink: 0;
}

.upload-zone {
    width: 678px;
    height: 10px;
    position: absolute;
    margin: 0 !important;
    top: 0px;
    left: calc(50% - 339px);
    border-radius: var(--br-5xs) var(--br-5xs) 0 0;
    background-color: var(--color-mediumslateblue-200);
    flex-shrink: 0;
    z-index: 1;
}

.desktop-1 {
    width: 100%;
    position: relative;
    border-radius: 16px;
    background-color: var(--white);
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 0 0 367px;
    box-sizing: border-box;
    gap: 25px;
    letter-spacing: normal;
    text-align: left;
    font-size: var(--font-size-lg);
    color: var(--label-color-light-primary);
    font-family: var(--font-roboto);
}

@media screen and (max-width: 900px) {
    .address-section {
        padding-top: var(--padding-15xl);
        padding-bottom: 25px;
        box-sizing: border-box;
        width: calc(100% - 40px);
    }

    .package-details {
        flex-direction: column!important;
    }
}

@media screen and (max-width: 450px) {
    .address-section {
        padding-top: 22px;
        padding-bottom: var(--padding-xl);
        box-sizing: border-box;
    }
}


.name1,
.to-address {
    position: relative;
    font-weight: 600;
}

.name1 {
    font-weight: 500;
}

.address-details-frame1 {
    border: 1px solid var(--color-darkgray-200);
    outline: 0;
    background-color: var(--white);
    align-self: stretch;
    height: 38px;
    position: relative;
    border-radius: var(--br-9xs);
    box-sizing: border-box;
    min-width: 142px;
    padding: var(--padding-3xs) var(--padding-28xl) var(--padding-3xs) var(--padding-xs);
}

.address-details-frame {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-5xs);
}

.country-code {
    position: relative;
    font-weight: 500;
}

.country-code-rectangle {
    border: 1px solid var(--color-darkgray-300);
    outline: 0;
    background-color: var(--white);
    align-self: stretch;
    height: 36px;
    position: relative;
    border-radius: var(--br-9xs);
    box-sizing: border-box;
    min-width: 142px;
}

.address-details-frame2 {
    align-self: stretch;
    gap: var(--gap-5xs);
}

.address-details-frame2,
.name-and-contact-frame,
.name-and-contact-frame1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.name-and-contact-frame1 {
    align-self: stretch;
    gap: var(--gap-base);
    font-size: var(--font-size-sm);
}

.name-and-contact-frame {
    flex: 1;
    gap: var(--gap-2xl);
    min-width: 153px;
}

.contact1 {
    position: relative;
    font-weight: 500;
    /* z-index: 1; */
}

.name-and-contact-frame-child {
    border: 1px solid var(--color-darkgray-300);
    outline: 0;
    background-color: var(--white);
    align-self: stretch;
    height: 36px;
    position: relative;
    border-radius: var(--br-9xs);
    box-sizing: border-box;
    min-width: 142px;
}

.country-code-input-frame,
.name-and-contact-frame2 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.name-and-contact-frame2 {
    flex: 1;
    flex-direction: column;
    /* padding: var(--padding-99xl) 0 0; */
    box-sizing: border-box;
    gap: var(--gap-5xs);
    min-width: 100%;
    font-size: var(--font-size-sm);
}

.country-code-input-frame {
    align-self: stretch;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap-base);
}

.address1 {
    position: relative;
    font-size: var(--font-size-sm);
    font-weight: 500;
}

.input-fields-frame2 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-base);
    max-width: 100%;
}

.street-name-area1 {
    /* width: 117px; */
    border: 0;
    outline: 0;
    font-family: var(--font-roboto);
    font-size: var(--font-size-xs);
    background-color: transparent;
    height: 16px;
    position: relative;
    line-height: 16px;
    color: var(--color-darkgray-100);
    text-align: left;
    display: inline-block;
}

.pincode-input-frame,
.street-area-frame {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    box-sizing: border-box;
    max-width: 100%;
}

.pincode-input-frame {
    flex: 1;
    border-radius: var(--br-9xs);
    background-color: var(--white);
    border: 1px solid var(--color-darkgray-200);
    overflow: hidden;
    align-items: center;
    padding: var(--padding-3xs) var(--padding-sm);
}

.street-area-frame {
    width: 100%;
    align-items: flex-start;
    padding: 0 0 var(--padding-5xs);
}

.pincode {
    position: relative;
    font-weight: 500;
}

.enter-pincode {
    width: 74px;
    border: 0;
    outline: 0;
    font-family: var(--font-roboto);
    font-size: var(--font-size-xs);
    background-color: transparent;
    height: 16px;
    position: relative;
    line-height: 16px;
    color: var(--color-darkgray-100);
    text-align: left;
    display: inline-block;
}

.pincode-input {
    align-self: stretch;
    border-radius: var(--br-9xs);
    background-color: var(--white);
    border: 1px solid var(--color-darkgray-200);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: var(--padding-3xs) var(--padding-sm);
}

.input-fields-frame1,
.shipment-dimension-frame {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-5xs);
}

.shipment-dimension-frame {
    width: 236px;
    font-size: var(--font-size-sm);
}

.input-fields-frame1 {
    align-self: stretch;
    border-radius: var(--br-5xs);
    border: 1px solid var(--color-gainsboro);
    box-sizing: border-box;
    padding: var(--padding-base) var(--padding-base) var(--padding-mid);
    max-width: 100%;
    /* z-index: 1; */
    text-align: left;
    font-size: var(--callout-bold-size);
    color: var(--label-color-light-primary);
    font-family: var(--font-roboto);
}



.address {
    position: relative;
    font-weight: 500;
}

.street-name-area {
    /* width: 117px; */
    border: 0;
    outline: 0;
    font-family: var(--font-roboto);
    font-size: var(--font-size-xs);
    background-color: transparent;
    height: 16px;
    position: relative;
    line-height: 16px;
    color: var(--color-darkgray-100);
    text-align: left;
    display: inline-block;
}

.frame1,
.nested-frames1 {
    align-self: stretch;
    display: flex;
    justify-content: flex-start;
}

.frame1 {
    border-radius: var(--br-9xs);
    background-color: var(--white);
    border: 1px solid var(--color-darkgray-200);
    overflow: hidden;
    flex-direction: row;
    align-items: center;
    padding: var(--padding-3xs) var(--padding-sm);
}

.nested-frames1 {
    flex-direction: column;
    align-items: flex-start;
    gap: var(--gap-5xs);
    text-align: left;
    font-size: var(--font-size-sm);
    color: var(--label-color-light-primary);
    font-family: var(--font-roboto);
}



.weight-k-g,
.width-input {
    align-self: stretch;
    max-width: 100%;
}

.width-input {
    flex: 1;
    position: relative;
    border-radius: var(--br-5xs) var(--br-5xs) 0 0;
    background-color: var(--color-mediumslateblue-200);
}

.weight-k-g {
    height: 10px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0;
    box-sizing: border-box;
    border: 0;
}

.weight-kg,
.weight1 {
    position: relative;
    font-family: var(--font-roboto);
    text-align: left;
    border: 0;
}

.weight-kg:focus {
    outline: none;
}

.weight1 {
    font-size: var(--font-size-sm);
    font-weight: 500;
    color: var(--label-color-light-primary);
}

.weight-kg {
    font-size: var(--font-size-xs);
    line-height: 16px;
    color: var(--color-darkgray-100);
    height: 38px;
    border-radius: var(--br-9xs);
    border: 1px solid var(--color-darkgray-300);
    padding: 10px;
    width: 100%;
}

.shipment-value {
    border-radius: var(--br-9xs);
    background-color: var(--white);
    border: 1px solid var(--color-darkgray-300);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: var(--padding-3xs) var(--padding-28xl) var(--padding-3xs) var(--padding-xs);
    white-space: nowrap;
}

.l-cm,
.length {
    position: relative;
    font-family: var(--font-roboto);
    text-align: left;
    border: 0;
}

.length {
    font-size: var(--font-size-sm);
    font-weight: 500;
    color: var(--label-color-light-primary);
}

.l-cm {
    font-size: var(--font-size-xs);
    line-height: 16px;
    color: var(--color-darkgray-100);
    outline: none;
    border-radius: var(--br-9xs);
    border: 1px solid var(--color-darkgray-300);
    /* padding: var(--padding-3xs) var(--padding-28xl) var(--padding-3xs) var(--padding-xs); */
    width: 100%;
    padding: 10px;
}

.length-input,
.value-of-shipment {
    display: flex;
    justify-content: flex-start;
}

.value-of-shipment {
    border-radius: var(--br-9xs);
    background-color: var(--white);
    border: 1px solid var(--color-darkgray-300);
    overflow: hidden;
    flex-direction: row;
    align-items: center;
    padding: var(--padding-3xs) var(--padding-31xl) var(--padding-3xs) var(--padding-xs);
    white-space: nowrap;
}

.length-input {
    align-self: stretch;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--gap-5xs);
}

.value-of-shipment1 {
    position: relative;
    font-size: var(--font-size-xs);
    font-weight: 500;
    font-family: var(--font-roboto);
    color: var(--label-color-light-primary);
    text-align: left;
    margin-top: 10px;
}

.size-input {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-5xl);
}

.w-cm,
.width {
    font-family: var(--font-roboto);
    position: relative;
    text-align: left;
}

.width {
    font-size: var(--font-size-sm);
    font-weight: 500;
    color: var(--label-color-light-primary);
}

.w-cm {
    width: 100%;
    border: 0;
    outline: 0;
    font-size: var(--font-size-xs);
    background-color: transparent;
    height: 16px;
    flex: 1;
    line-height: 16px;
    color: var(--color-darkgray-100);
    display: inline-block;
    /* min-width: 26px; */
    outline: none;
    border-radius: var(--br-9xs);
    border: 1px solid var(--color-darkgray-300);
    padding: 10px;
}

.weight-k-g1 {
    align-self: stretch;
    border-radius: var(--br-9xs);
    background-color: var(--white);
    border: 1px solid var(--color-darkgray-300);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: var(--padding-3xs) var(--padding-26xl) var(--padding-3xs) var(--padding-smi);
}

.dimensions,
.shipment-details2 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.shipment-details2 {
    flex-direction: column;
    gap: var(--gap-5xs);
    align-self: stretch;
}

.dimensions {
    align-self: stretch;
    flex-direction: row;
    gap: var(--gap-5xl);
    flex-wrap: wrap;
}

.shipment-info-child {
    border: 1px solid var(--color-darkgray-300);
    outline: 0;
    background-color: var(--white);
    align-self: stretch;
    height: 36px;
    position: relative;
    border-radius: var(--br-9xs);
    box-sizing: border-box;
    min-width: 137px;
    padding: var(--padding-3xs) var(--padding-26xl) var(--padding-3xs) var(--padding-smi);
}

.shipment-info {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-5xs);
}

.b-cm,
.breadth {
    font-family: var(--font-roboto);
    position: relative;
    text-align: left;
}

.breadth {
    font-size: var(--font-size-sm);
    font-weight: 500;
    color: var(--label-color-light-primary);
}

.b-cm {
    width: 100%;
    border: 0;
    outline: 0;
    font-size: var(--font-size-xs);
    background-color: transparent;
    height: 16px;
    flex: 1;
    line-height: 16px;
    color: var(--color-darkgray-100);
    display: inline-block;
    /* min-width: 25px; */
    outline: none;
    border-radius: var(--br-9xs);
    border: 1px solid var(--color-darkgray-300);
    padding: 10px;
}

.package-count {
    width: 100%;
    border-radius: var(--br-9xs);
    border: 1px solid var(--color-darkgray-300);
    padding: var(--padding-3xs) var(--padding-28xl) var(--padding-3xs) var(--padding-xs);
    height: 38px;
}

.enter-pincode1 {
    align-self: stretch;
    border-radius: var(--br-9xs);
    background-color: var(--white);
    border: 1px solid var(--color-darkgray-300);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: var(--padding-3xs) var(--padding-30xl) var(--padding-3xs) var(--padding-xs);
}

.breadth-input1 {
    /* width: 102px; */
    flex-direction: column;
    gap: var(--gap-5xs);
    align-self: stretch;
}

.b-c-m-input,
.breadth-input1,
.package-details {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.package-details {
    align-self: stretch;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap-5xl);
}

.b-c-m-input {
    /* width: 354px; */
    width: 100%;
    flex-direction: column;
    gap: var(--gap-5xs);
    max-width: 100%;
}

.picture-of-shipment1 {
    position: relative;
    font-size: var(--font-size-xs);
    text-transform: capitalize;
    font-weight: 600;
    font-family: var(--font-roboto);
    color: var(--label-color-light-primary);
    text-align: left;
}

.document-1-icon {
    height: 12px;
    width: 12px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    /* z-index: 2; */
}

.shipmentjpg {
    position: relative;
    font-size: var(--font-size-xs);
    font-weight: 500;
    font-family: var(--font-poppins);
    color: var(--color-mediumslateblue-300);
    text-align: left;
}

.shipment-image-label {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.shipment-image {
    border: 0;
}

.eye-1-1 {
    height: 16px;
    width: 16px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
}

.document-frame,
.shipment-jpg {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.shipment-jpg {
    width: 228px;
    border-radius: var(--br-11xs);
    background-color: var(--color-mediumslateblue-400);
    flex-shrink: 0;
    align-items: center;
    padding: var(--padding-6xs) var(--padding-15xl) var(--padding-7xs) var(--padding-xs);
    box-sizing: border-box;
    gap: var(--gap-6xs);
    /* z-index: 1; */
}

.document-frame {
    cursor: pointer;
    border: 0;
    padding: 0;
    background-color: transparent;
    flex: 1;
    align-items: flex-start;
}

.cloud-upload-icon {
    height: 16px;
    width: 16px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
}

.frame-for-image {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-7xs);
    min-width: 142px;
}

.cloud-computing-1-icon {
    height: 20px;
    width: 20px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
}

.upload {
    position: relative;
    font-size: var(--font-size-sm);
    font-weight: 600;
    font-family: var(--font-poppins);
    color: var(--white);
    text-align: left;
}

.multi-packages-indicator {
    cursor: pointer;
    border: 0;
    padding: var(--padding-xs) var(--padding-xs) var(--padding-xs) var(--padding-mini);
    background-color: var(--color-mediumslateblue-200);
    height: 46px;
    border-radius: 0 var(--br-9xs) var(--br-9xs) 0;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    box-sizing: border-box;
    gap: var(--gap-7xs);
    white-space: nowrap;
}

.multi-packages-indicator:hover,
.referral-section:hover {
    background-color: var(--color-mediumslateblue-100);
}

.cloud-computing-icon,
.picture-of-shipment {
    align-self: stretch;
    display: flex;
    justify-content: flex-start;
}

.cloud-computing-icon {
    border-radius: var(--br-9xs);
    background-color: var(--white);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.02);
    border: 1px solid var(--color-darkgray-200);
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    padding: 0 var(--padding-11xs) 0 var(--padding-3xs);
    gap: var(--gap-28xl);
    justify-content: space-between;
}

.picture-of-shipment {
    flex-direction: column;
    align-items: flex-start;
    gap: var(--gap-3xs);
    min-height: 75px;
}

.rectangle-input {
    margin: 0;
    height: 14px;
    width: 14px;
    position: relative;
}

.please-click-if {
    position: relative;
    font-size: var(--font-size-xs);
    line-height: 24px;
    font-family: var(--font-roboto);
    color: var(--label-color-light-primary);
    text-align: left;
}

.breadth-input,
.document-frame1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-5xs);
    max-width: 100%;
}

.breadth-input {
    margin: 0;
    /* width: 386px; */
    flex-direction: column;
    align-items: flex-start;
    gap: var(--gap-5xl);
}

.optional {
    color: var(--color-dimgray-200);
}

.refered-by-optional-container {
    position: relative;
    line-height: 24px;
    font-weight: 600;
}

.shipment-details-frame,
.submit-request-button {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 var(--padding-9xs);
}

.shipment-details-frame {
    align-self: stretch;
    border-radius: var(--br-9xs);
    background-color: var(--white);
    border: 1px solid var(--color-darkgray-200);
    overflow: hidden;
    align-items: center;
    justify-content: space-between;
    padding: var(--padding-5xs) var(--padding-4xs) var(--padding-5xs) var(--padding-mini);
    font-family: var(--font-roboto);
    font-size: var(--font-size-xs);
    color: var(--color-darkgray-100);
    gap: var(--gap-xl);
    white-space: nowrap;
}

.checkbox-label {
    width: 326px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-10xs);
    max-width: 100%;
}

.this-is-a,
.with-you-within {
    margin: 0;
}

.this-is-a-container {
    position: relative;
    font-family: var(--font-inter);
}

.icons-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-10xl);
    max-width: 100%;
}

.rectangle-input1 {
    margin: 0;
    height: 14px;
    width: 14px;
    position: relative;
}

.i-hereby-consent {
    position: relative;
    line-height: 24px;
}

.breadth-field-label,
.height,
.w-c-m-input {
    display: flex;
    justify-content: flex-start;
    max-width: 100%;
}

.breadth-field-label {
    flex-direction: row;
    align-items: center;
    gap: var(--gap-5xs);
}

.height,
.w-c-m-input {
    align-items: flex-start;
}

.height {
    flex-direction: column;
    gap: var(--gap-21xl);
}

.w-c-m-input {
    flex-direction: row;
    padding: 20px;
    /* padding: 0 var(--padding-2xs) 0 0; */
    box-sizing: border-box;
    min-height: 620px;
}

.submit-request {
    width: 129px;
    position: relative;
    font-size: var(--callout-bold-size);
    font-weight: 600;
    font-family: var(--font-roboto);
    color: var(--white);
    text-align: left;
    display: inline-block;
    flex-shrink: 0;
}

.referral-section {
    cursor: pointer;
    border: 0;
    padding: var(--padding-xs) var(--padding-xl) var(--padding-smi) var(--padding-2xl);
    background-color: var(--color-mediumslateblue-200);
    flex: 1;
    border-radius: var(--br-7xs);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
}

.order-info-box,
.weight {
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
}

.order-info-box {
    /* width: 298px; */
    flex-direction: row;
    align-items: flex-start;
    padding: 0 0 0 var(--padding-5xs);
}

.weight {
    align-self: stretch;
    border-radius: var(--br-5xs);
    background-color: var(--white);
    border: 1px solid var(--color-darkgray-300);
    overflow: hidden;
    flex-direction: column;
    align-items: center;
    padding: 0 0 var(--padding-84xl);
    gap: var(--gap-12xl);
    max-width: 100%;
    flex-shrink: 0;
    text-align: left;
    font-size: var(--font-size-xs);
    color: var(--label-color-light-primary);
    font-family: var(--font-roboto);
}

@media screen and (max-width: 900px) {
    .weight {
        padding-bottom: var(--padding-48xl);
        box-sizing: border-box;
    }
}

@media screen and (max-width: 675px) {
    .height {
        gap: var(--gap-21xl);
    }

    .weight {
        gap: var(--gap-12xl);
    }
}

@media screen and (max-width: 450px) {
    .cloud-computing-icon {
        gap: var(--gap-28xl);
    }

    .weight {
        padding-bottom: var(--padding-25xl);
        box-sizing: border-box;
    }
}






/* ************************************************************************************************************* */
body {
    margin: 0;
    font-family: 'Inter', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    cursor: default;
    /* background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, #b1c8ff 75vh); */
    background: #fff;
    min-height: 100vh;
    max-width: 100vw;
}


/* Hide scrollbar for Chrome, Safari and Opera */

.home-fullwidth-on-mobile::-webkit-scrollbar {
    display: none;
}


/* Hide scrollbar for IE, Edge and Firefox */

.home-fullwidth-on-mobile {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}


/* GENERAL STYLES */

@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,900;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Calistoga&display=swap');

.navbar-light .navbar-nav .nav-link {
    color: #2E3539;
    /* display: flex;
    justify-content: center;
    align-items: center; */
}

.hidden {
    visibility: hidden;
}

.pac-container {
    z-index: 10000 !important;
}

a {
    color: inherit;
    text-decoration: none;
}

a:active,
a:hover {
    color: #d73b7d;
    text-decoration: none;
}

::-webkit-scrollbar-thumb {
    background: #d73b7d;
    border-radius: 10px;
}

input {
    border: 1px solid #d4d4d4;
}

/* Maintenance */
@media only screen and (max-width:991px) {
    .maintenance-wrapper>img {
        zoom: 0.6!important
    }

    .maintenance-wrapper>p {
        font-size: 20px!important;
    }
}

textarea,
select
/* input:not(.mantine-Checkbox-input):not(.otp-input)  */

    {
    border: #ffcee2 2px solid;
    /* padding: 5px 15px; */
    font-weight: 500;
    border-radius: 5px;
    -moz-outline-radius: 5px;
}

input:focus-visible,
textarea:focus-visible {
    outline-color: #706b6d;
    outline-style: auto;
    outline-width: 2px;
}

input[type=file] {
    border: none !important;
    outline-color: #ffffff00;
}

.mantine-Select-input {
    height: 38px !important;
}

.checkbox-wrapper input {
    padding: 0px;
}

img {
    object-fit: contain;
}

.btn.toggle-btn.btn-outline-primary {
    /* border: 1px solid #A3B9F1; */
    border: 1px solid #A3A3A3;
    /* color: #3F3F3F; */
    color: #A3A3A3;
}

.btn.toggle-btn.btn-primary {
    background: #456ED6;
    color: '#FFFFFF'
}

.form-input-label {
    font-weight: 600;
    font-size: 11px;
    color: #626265;
    margin-top: 10px;
    margin-bottom: 5px;
}

.form-input,
.form-input:focus,
.form-input:-webkit-autofill,
.form-input:-webkit-autofill:hover,
.form-input:-webkit-autofill:focus,
.form-input:-webkit-autofill:active {
    font-size: 12px;
    font-weight: 500;
    color: #626265 !important;
}


/* Firefox */

input[type=number] {
    -moz-appearance: textfield;
}

input.form-range {
    border: none !important;
}

input.form-range::-webkit-slider-thumb {
    background: #d73b7d;
}

.form-control:disabled,
.form-control[readonly] {
    background-color: #e9ecef !important;
}

input:disabled,
textarea:disabled {
    border: none !important;
}

.dropdown-select {
    background: white !important;
    color: #575757 !important;
    font-weight: normal !important;
    border: #ffcee2 2px solid !important;
    transition: all 0.4s ease-out;
}

.dropdown-select:hover,
.dropdown-select:active,
.dropdown-select:focus,
.show>.btn-primary.dropdown-toggle {
    background: white !important;
    color: #d73b7d !important;
}

.from-color-input {
    /* background: #2acdc250 !important; */
    /* border: #2acdc29c 2px solid !important; */
    background: #b1c7fa4a !important;
    border: #c1d3fb 2px solid !important;
}

.to-color-input {
    /* background: #FF866750 !important;
  border: #FF86679c 2px solid !important; */
    background: #b1c7fa4a !important;
    border: #c1d3fb 2px solid !important;
}

input.email-error {
    border: 0;
    border-bottom: 1px solid red !important;
}

.landing-error input,
.validation-error,
input.validation-error,
textarea.validation-error {
    border: rgb(241, 169, 169) 2px solid !important;
    background-color: rgb(255, 232, 232) !important;
}

.validation-error,
input.validation-error:focus-visible,
textarea.validation-error:focus-visible {
    outline-color: rgb(241, 169, 169) !important;
}

.validation-error,
.validation-error::placeholder {
    color: rgb(184, 33, 33) !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.typewriter-cursor {
    color: #d63a7c;
    font-family: Arial, Helvetica, sans-serif;
}

.btn {
    font-weight: 800;
    border-radius: 5px;
}

.btn-white {
    background-color: #fff;
    box-shadow: 0px 2px 8px 0px rgb(0 0 0 / 40%);
}

.btn-white:hover {
    background-color: #fff;
    box-shadow: 0px 4px 10px 2px rgb(0 0 0 / 30%);
}


/* .btn-primary {
    color: #fff;
    background-color: #d73b7d;
    border-color: #d73b7d;
}

.btn-primary:hover {
    color: #fff;
    background-color: #aa1c57;
    border-color: #aa1c57;
} */


/* .btn-check:active+.btn-primary,
.btn-check:checked+.btn-primary,
.btn-primary.active,
.btn-primary:active,
.show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #aa1c57;
    border-color: #aa1c57;
} */


/* .btn-check:focus+.btn-primary,
.btn-primary:focus {
    color: #fff;
    background-color: #aa1c57;
    border-color: #aa1c57;
    box-shadow: 0 0 0 0.25rem rgb(215 59 125 / 40%);
}

.btn-primary-outline {
    border: #d73b7d 2px solid;
}

.btn-primary-outline:hover {
    color: #fff;
    background-color: #d73b7d;
    border-color: #d73b7d;
}

.btn-check:active+.btn-primary-outline,
.btn-check:checked+.btn-primary-outline,
.btn-primary-outline.active,
.btn-primary-outline:active,
.show>.btn-primary-outline.dropdown-toggle {
    color: #fff;
    background-color: #d73b7d;
    border-color: #d73b7d;
}

.btn-check-outline:focus+.btn-primary-outline,
.btn-primary-outline:focus {
    color: #fff !important;
    background-color: #d73b7d !important;
    border-color: #d73b7d !important;
    box-shadow: 0 0 0 0.25rem rgb(215 59 125 / 40%);
} */

.btn.disabled,
.btn:disabled {
    color: #fff !important;
    background-color: #728079 !important;
    border-color: #728079 !important;
    opacity: 0.4;
}

.submitted {
    color: #87eebe !important;
    background-color: #0a643a !important;
    border-color: #0a643a !important;
}

.input-container {
    border: #ffcee2 2px solid !important;
    background: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    border-radius: 5px;
    -moz-outline-radius: 5px;
}

.input-container>input {
    border: none !important;
    padding: none;
    margin-left: 10px;
}

.input-container>input:focus-visible {
    border: none !important;
    outline-color: #fff;
}

.city-modal {
    /* width: 50% !important; */
    position: fixed;
    top: auto;
    left: auto;
    right: auto;
    bottom: 0;
    margin: 0;
    height: 556px;
    max-width: 100%;
    width: 100%;
    /* transition: bottom 0.3s ease-out; */
}

.modal.fade .modal-dialog.city-modal {
    /* transition: transform 0.4s ease-out; */
    transition: transform 0.4s cubic-bezier(0.37, 0.04, 1, 1);
    transform: translate(0, 0);
}

.city-modal .modal-content {
    border-radius: 20px 20px 0 0;
    height: 100%;
}

.card-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.primary-text {
    font-size: 15pt;
    font-weight: 700;
    align-self: flex-start;
    width: 100%;
}

.secondary-text {
    font-size: 12pt;
    font-weight: 600;
    align-self: flex-start;
    width: 100%;
}

.ternary-text {
    font-size: 10pt;
    font-weight: 600;
    opacity: 0.7;
}

.blue-text {
    color: #05336f;
}

.magenta-text {
    color: #d73b7d
}

.clickable-link {
    cursor: pointer;
    color: #d73b7d;
    text-decoration: underline;
}

.clickable-link:hover {
    color: #d63a7c;
}

.center-align {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.navbar-collapse.collapsing {
    position: absolute;
    top: 50px;
    left: 0px;
    right: 0px;
    padding: 10px;
}

.navbar-collapse.collapse.show {
    position: absolute;
    top: 50px;
    left: 0px;
    right: 0px;
    padding: 10px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}

.header-fixed .navbar-collapse.collapsing {
    background: rgb(255, 255, 255);
    box-shadow: rgb(0 0 0 / 20%) 0px 5px 4px 0px;
    z-index: -1;
}

.header-fixed .navbar-collapse.collapse.show {
    background: rgb(255, 255, 255);
    box-shadow: rgb(0 0 0 / 20%) 0px 5px 4px 0px;
    z-index: -1;
}

.loader {
    border: 4px solid #f3f3f300;
    /* Light grey */
    border-top: 4px solid #ffffff;
    /* Blue */
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 0.6s linear infinite;
}

.loader-magenta {
    border: 4px solid #f3f3f300;
    border-top: 4px solid #d63a7c;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 0.6s linear infinite;
}

.loader-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* position: absolute; */
    width: 100%;
    height: 0;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


/* 
.vert .carousel-item-next.carousel-item-left,
.vert .carousel-item-prev.carousel-item-right {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
} */

.vert .carousel-item-next:not(.carousel-item-start),
.vert .active.carousel-item-end {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
}

.vert.carousel-item-prev:not(.carousel-item-end),
.vert .active.carousel-item-start {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
}

.smooth {
    transition: max-height 0.5s ease-in;
}

.reverse-slider {
    transform: rotate(180deg);
}

.reverse-slider img {
    transform: rotate(180deg);
}

#fc_frame {
    z-index: 5 !important;
}

.padding-16 {
    padding: 16px;
}

#fc_frame {
    z-index: 5 !important;
}

.usp-card {
    margin-top: 0px;
    margin-bottom: 0px;
    transition: all 100ms ease-in;
    cursor: pointer;
    height: 100%;
}

.usp-card.active {
    margin-top: -5px;
    margin-bottom: 5px;
    color: #fff;
    font-weight: 600;
    background: linear-gradient(35deg, rgb(120 147 255) 0%, rgb(63 63 220) 46%, rgb(50 160 199) 100%);
    border-color: rgba(77, 110, 245, 1) !important;
}